.footer {
  background: #4b5864;
  padding: 12px;
  color: white;
}

#emaillink {
  text-transform: none;
  text-decoration: none;
  color: white;
}
