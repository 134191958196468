.content {
  min-height: calc(100vh - 129px);
  padding-left: 16px;
  padding-right: 16px;
}

.top-toolbar {
  height: 45px;
  margin-top: 10px;
}

.loading-spinner {
  text-align: center;
  width: 100%;
}

.error {
  margin: 50px;
  margin-top: 200px;
  margin-bottom: 200px;
}

.float-right {
  float: right;
}

.submit-button {
  float: right;
  margin-bottom: 50px !important;
}

.high-contrast input,
.high-contrast textarea {
  color: black !important;
}

.left-button {
  margin: 6px !important;
}

iframe {
  border: none;
  overflow: scroll;
  height: 100%;
}

.MuiInputBase-input {
  color: black;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  color: black;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
  box-shadow: 0px 3px 1px -2px rgb(104 198 180), 0px 2px 2px 0px rgb(104 198 180), 0px 1px 5px 0px rgb(104 198 180);
}

.MuiGrid-root.footer.MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12 {
  margin-top: 2px;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft,
.MuiPaper-root {
  color: #000;
}

.MuiTooltip-tooltip {
  font-size: 0.760rem!important;
}

.popup-close-button {
  position: absolute!important;
  top: 5px;
  right: 5px;
}

#simple-dialog-title {
  padding-bottom: 0px!important;
}

.popup-subtitle { 
  padding-bottom: 10px;
  font-weight: 500;
}

#preloaded-messages-form {
  margin-left: 5px;
}

.unlockIcon {
  margin-left: -15px!important;
  padding: 8px!important;
}